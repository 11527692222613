// app-config.ts
import { RuntimeConfig } from "@src/runtime-config";

function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

const sentryDsn =
  "https://a5f0e653e508490282b7d14d19443b9f@o381590.ingest.sentry.io/5580037";

export const appVersion: string = "3.0.0-feature-ST-2421-update-dompurify.1";
export const appTimestamp: string = "2024-11-21T15:18:43.000Z";
export const commitId: string = "536d0c1e6f78efce0af3888501153abdf9d1c52f";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/commit/536d0c1e6f78efce0af3888501153abdf9d1c52f?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "29416";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/pullrequest/29416";
export const jiraTicketId: string = "ST-2421";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-2421";

const isNumberedEnv = isHostNumberedEnv(window.location.host);
const numberedEnv = isNumberedEnv ? getNumberedEnv(window.location.host) : null;

const developmentEu = {
  urls: {
    apiUrl: "https://core.api.dev.holobuilder.eu",
    baseUrl: "https://admin.dev.holobuilder.eu",
    dashboardUrl: "https://dashboard.dev.holobuilder.eu",
    webEditorUrl: "https://app.dev.holobuilder.eu/app",
    entryPageUrl: "https://workspace.dev.holobuilder.eu",
    adminPanelApi: "https://admin.dev.holobuilder.eu/api",
  },
  deployment: {
    topLevelDomain: "eu",
    environment: "development",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "eu-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const developmentCom = {
  urls: {
    apiUrl: "https://core.api.dev.holobuilder.com",
    baseUrl: "https://admin.dev.holobuilder.com",
    dashboardUrl: "https://dashboard.dev.holobuilder.com",
    webEditorUrl: "https://app.dev.holobuilder.com/app",
    entryPageUrl: "https://workspace.dev.holobuilder.com",
    adminPanelApi: "https://admin.dev.holobuilder.com/api",
  },
  deployment: {
    topLevelDomain: "intl",
    environment: "development",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "com-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingEu = {
  urls: {
    apiUrl: "https://core.api.staging.holobuilder.eu",
    baseUrl: "https://admin.staging.holobuilder.eu",
    dashboardUrl: "https://dashboard.staging.holobuilder.eu",
    webEditorUrl: "https://app.staging.holobuilder.eu/app",
    entryPageUrl: "https://workspace.staging.holobuilder.eu",
    adminPanelApi: "https://admin.staging.holobuilder.eu/api",
  },
  deployment: {
    topLevelDomain: "eu",
    environment: "staging",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "eu-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingCom = {
  urls: {
    apiUrl: "https://core.api.staging.holobuilder.com",
    baseUrl: "https://admin.staging.holobuilder.com",
    dashboardUrl: "https://dashboard.staging.holobuilder.com",
    webEditorUrl: "https://app.staging.holobuilder.com/app",
    entryPageUrl: "https://workspace.staging.holobuilder.com",
    adminPanelApi: "https://admin.staging.holobuilder.com/api",
  },
  deployment: {
    topLevelDomain: "intl",
    environment: "staging",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "com-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const prodEu = {
  urls: {
    apiUrl: "https://core.api.holobuilder.eu",
    baseUrl: "https://admin.holobuilder.eu",
    dashboardUrl: "https://dashboard.holobuilder.eu",
    webEditorUrl: "https://app.holobuilder.eu/app",
    entryPageUrl: "https://workspace.holobuilder.eu",
    adminPanelApi: "https://admin.holobuilder.eu/api",
  },
  deployment: {
    topLevelDomain: "eu",
    environment: "production",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "eu-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodCom = {
  urls: {
    apiUrl: "https://core.api.holobuilder.com",
    baseUrl: "https://admin.holobuilder.com",
    dashboardUrl: "https://dashboard.holobuilder.com",
    webEditorUrl: "https://app.holobuilder.com/app",
    entryPageUrl: "https://workspace.holobuilder.com",
    adminPanelApi: "https://admin.holobuilder.com/api",
  },
  deployment: {
    topLevelDomain: "intl",
    environment: "production",
  },
  keys: {
    sentryDsn,
  },
  appEnv: "com-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentEuLocal = {
  ...developmentEu,
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentComLocal = {
  ...developmentCom,
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingEuLocal = {
  ...stagingEu,
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingComLocal = {
  ...stagingCom,
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodEuLocal = {
  ...prodEu,
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodComLocal = {
  ...prodCom,
  isNumberedEnv: false,
  numberedEnv: null,
};

export const runtimeConfigObject: { [envName: string]: RuntimeConfig } = {
  developmentEu,
  developmentCom,
  stagingEu,
  stagingCom,
  prodEu,
  prodCom,
  developmentEuLocal,
  developmentComLocal,
  stagingEuLocal,
  stagingComLocal,
  prodEuLocal,
  prodComLocal,
};
